import variables from '@/styles/element-variables.scss';
import defaultSettings from '@/settings';
var showSettings = defaultSettings.showSettings,
    tagsView = defaultSettings.tagsView,
    fixedHeader = defaultSettings.fixedHeader,
    sidebarLogo = defaultSettings.sidebarLogo;
var state = {
  theme: variables.theme,
  showSettings: showSettings,
  tagsView: tagsView,
  fixedHeader: fixedHeader,
  sidebarLogo: sidebarLogo
};
var mutations = {
  CHANGE_SETTING: function CHANGE_SETTING(state, _ref) {
    var key = _ref.key,
        value = _ref.value;

    if (state.hasOwnProperty(key)) {
      state[key] = value;
    }
  }
};
var actions = {
  changeSetting: function changeSetting(_ref2, data) {
    var commit = _ref2.commit;
    commit('CHANGE_SETTING', data);
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};