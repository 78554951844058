import "core-js/modules/es6.regexp.split";
import _objectSpread from "/builds/ytw/admin/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import RightPanel from '@/components/RightPanel';
import { AppMain, Navbar, Settings, Sidebar, TagsView } from "./components";
import ResizeMixin from "./mixin/ResizeHandler";
import { mapState } from 'vuex';
export default {
  name: 'Layout',
  components: {
    AppMain: AppMain,
    Navbar: Navbar,
    RightPanel: RightPanel,
    Settings: Settings,
    Sidebar: Sidebar,
    TagsView: TagsView
  },
  mixins: [ResizeMixin],
  computed: _objectSpread(_objectSpread({}, mapState({
    sidebar: function sidebar(state) {
      return state.app.sidebar;
    },
    device: function device(state) {
      return state.app.device;
    },
    showSettings: function showSettings(state) {
      return state.settings.showSettings;
    },
    needTagsView: function needTagsView(state) {
      return state.settings.tagsView;
    },
    fixedHeader: function fixedHeader(state) {
      return state.settings.fixedHeader;
    }
  })), {}, {
    classObj: function classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile'
      };
    },
    menuLevel: function menuLevel() {
      var route = this.$route;
      var meta = route.meta,
          path = route.path;

      if (path.indexOf('/welcome/') != -1) {
        return 2;
      }

      return path.split('/').length;
    },
    testEnv: function testEnv() {
      return process.env['VUE_APP_ENV_NAME'] === 'test';
    }
  }),
  methods: {
    handleClickOutside: function handleClickOutside() {
      this.$store.dispatch('app/closeSideBar', {
        withoutAnimation: false
      });
    }
  },
  mounted: function mounted() {
    if (this.testEnv) {
      console.log('测试环境，修改配色。');
      this.$store.dispatch('settings/changeSetting', {
        key: 'theme',
        value: '#6959CD'
      });
      this.$message.warning("您正在访问的是体验版，数据可能会定期清除。");
    }
  }
};