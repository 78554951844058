import "core-js/modules/es6.regexp.split";
import _objectSpread from "/builds/ytw/admin/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import path from "path";
import { mapGetters } from "vuex";
import { isExternal } from "@/utils/validate";
import Logo from "./Logo";
import SidebarItem from "./SidebarItem";
import SidebarItemTwo from "./SidebarItemTwo";
import SidebarItemOne from "./SidebarItemOne";
import _variables from "@/styles/variables.scss";
import { version } from "../../../../package.json";
export default {
  components: {
    SidebarItem: SidebarItem,
    SidebarItemTwo: SidebarItemTwo,
    SidebarItemOne: SidebarItemOne,
    Logo: Logo
  },
  data: function data() {
    return {
      title: "",
      sub_routes: [],
      basePath: "/",
      version: version,
      build: process.env.VUE_APP_BUILD || 'dev',
      isYt: /https:\/\/yt\./.test(location.href)
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(["permission_routes", "sidebar"])), {}, {
    activeMenu: function activeMenu() {
      var route = this.$route;
      var meta = route.meta,
          path = route.path;

      for (var i = 0; i < this.permission_routes.length; i++) {
        if (path.indexOf(this.permission_routes[i].path + '/') != -1 && this.permission_routes[i].path != "/") {
          this.sub_routes = this.permission_routes[i].children;
          this.title = this.permission_routes[i].meta.title;
          this.basePath = this.permission_routes[i].path;
        }
      }

      if (meta.activeMenu) {
        return meta.activeMenu;
      }

      return path;
    },
    menuLevel: function menuLevel() {
      var route = this.$route;
      var meta = route.meta,
          path = route.path;

      if (path.indexOf('/welcome/') != -1) {
        return 2;
      }

      return path.split("/").length;
    },
    showLogo: function showLogo() {
      return this.$store.state.settings.sidebarLogo;
    },
    variables: function variables() {
      return _variables;
    },
    isCollapse: function isCollapse() {
      return !this.sidebar.opened;
    }
  }),
  methods: {
    test: function test(data, basePath) {
      this.sub_routes = data;
      this.basePath = basePath;
    },
    resolvePath: function resolvePath(routePath) {
      if (isExternal(routePath)) {
        return routePath;
      }

      if (isExternal(this.basePath)) {
        return this.basePath;
      }

      return path.resolve(this.basePath, routePath);
    }
  }
};