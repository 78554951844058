import { render, staticRenderFns } from "./EmptyTemplate.vue?vue&type=template&id=7598960a&"
import script from "./EmptyTemplate.vue?vue&type=script&lang=js&"
export * from "./EmptyTemplate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/ytw/admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7598960a')) {
      api.createRecord('7598960a', component.options)
    } else {
      api.reload('7598960a', component.options)
    }
    module.hot.accept("./EmptyTemplate.vue?vue&type=template&id=7598960a&", function () {
      api.rerender('7598960a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/layout/EmptyTemplate.vue"
export default component.exports