var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-wrapper", class: _vm.classObj },
    [
      _vm.device === "mobile" && _vm.sidebar.opened
        ? _c("div", {
            staticClass: "drawer-bg",
            on: { click: _vm.handleClickOutside }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("sidebar", {
        staticClass: "sidebar-container no-print",
        class: _vm.menuLevel > 2 ? "w-250" : "w-130"
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "main-container",
          class: {
            "mg-l-250": _vm.menuLevel > 2,
            "mg-l-130": _vm.menuLevel == 2,
            hasTagsView: _vm.needTagsView
          }
        },
        [
          _c(
            "div",
            {
              class: {
                "fixed-header-250": _vm.fixedHeader && _vm.menuLevel > 2,
                "fixed-header-130": _vm.fixedHeader && _vm.menuLevel == 2,
                hasTagsView: _vm.needTagsView,
                "no-print": true
              }
            },
            [
              _c("navbar"),
              _vm._v(" "),
              _vm.needTagsView ? _c("tags-view") : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c("app-main"),
          _vm._v(" "),
          _c("settings", { staticStyle: { display: "none" } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }