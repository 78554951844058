//
//
//
//
//
import { AppMain } from "./components";
export default {
  name: 'EmptyTemplate',
  components: {
    AppMain: AppMain
  }
};