import { isExternal } from "@/utils/validate";
export default {
  name: "Link",
  functional: true,
  props: {
    to: {
      type: String,
      required: true
    }
  },
  render: function render(h, context) {
    var props = context.props,
        scopedSlots = context.scopedSlots;
    var to = props.to;

    if (isExternal(to)) {
      return h("a", {
        attrs: {
          target: "_blank",
          rel: "noopener",
          href: to
        }
      }, scopedSlots.default());
    } else {
      return h("router-link", {
        props: {
          to: to
        }
      }, // scopedSlots.default()
      context.children);
    }
  }
};