import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "/builds/ytw/admin/node_modules/core-js/modules/es6.array.iterator.js";
import "/builds/ytw/admin/node_modules/core-js/modules/es6.promise.js";
import "/builds/ytw/admin/node_modules/core-js/modules/es6.object.assign.js";
import "/builds/ytw/admin/node_modules/core-js/modules/es7.promise.finally.js";
import Vue from 'vue';
import Cookies from 'js-cookie';
import 'normalize.css/normalize.css'; // a modern alternative to CSS resets

import Element from 'element-ui';
import "./styles/element-variables.scss";
import '@/styles/index.scss'; // global css

import App from "./App";
import store from "./store";
import router from "./router";
import "./icons"; // icon

import "./permission"; // permission control

import "./utils/error-log"; // error log

import * as filters from "./filters"; // global filters

import vueSwiper from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
import moment from 'moment';
moment.locale('zh-cn');
Vue.use(vueSwiper);
import VueAMap from 'vue-amap';
Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
  key: '4881b4b13c0a8d1e91061233e0f337cf',
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor', 'Geocoder'],
  // 默认高德 sdk 版本为 1.4.4
  v: '1.4.4',
  uiVersion: '1.0.11' // ui版本号，也是需要写

});
/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online! ! !
 */
// import { mockXHR } from '../mock'
// if (process.env.NODE_ENV === 'production') {
//   mockXHR()
// }

Vue.use(Element, {
  size: Cookies.get('size') || 'medium' // set element-ui default size

}); // register global utility filters

Object.keys(filters).forEach(function (key) {
  Vue.filter(key, filters[key]);
});
Vue.config.productionTip = false;
new Vue({
  el: '#app',
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
});