//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import logo from '@/assets/img/logo.png';
export default {
  name: "SidebarLogo",
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data: function data() {
    return {
      title: "",
      logo: logo
    };
  }
};