export default {
  'BadRequest': '请求参数错误',
  'Bad Request': '请求参数错误',
  'Unauthorized': '您还未登录，请先登录后再访问',
  'Forbidden': '您无权访问此资源',
  'NotFound': '您所访问的资源不存在',
  'Not Found': '您所访问的资源不存在',
  'MethodNotAllowed': '当前资源不支持此方法访问',
  'NotAcceptable': '无效的访问方式',
  "RequestTimeout": '请求超时',
  "Gone": '资源不可用',
  'Conflict': '数据有冲突，请检查后重新输入',
  "PayloadTooLarge": '请求数据过大，服务器无法处理',
  "Locked": '您所访问的资源已被锁定',
  "BadGateway": '服务器暂不可用',
  'TooManyRequests': '请求过频繁',
  'Network Error': '网络错误，请检查您的网络连接',
  'default': '系统未知错误,请反馈给管理员'
};