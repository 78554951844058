import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.number.constructor";
// import parseTime, formatTime and set to filter
export { parseTime, formatTime } from '@/utils';
/**
 * Show plural label if time is plural number
 * @param {number} time
 * @param {string} label
 * @return {string}
 */

function pluralize(time, label) {
  if (time === 1) {
    return time + label;
  }

  return time + label + 's';
}
/**
 * @param {number} time
 */


export function timeAgo(time) {
  var between = Date.now() / 1000 - Number(time);

  if (between < 3600) {
    return pluralize(~~(between / 60), ' minute');
  } else if (between < 86400) {
    return pluralize(~~(between / 3600), ' hour');
  } else {
    return pluralize(~~(between / 86400), ' day');
  }
}
/**
 * Number formatting
 * like 10000 => 10k
 * @param {number} num
 * @param {number} digits
 */

export function numberFormatter(num, digits) {
  var si = [{
    value: 1e18,
    symbol: 'E'
  }, {
    value: 1e15,
    symbol: 'P'
  }, {
    value: 1e12,
    symbol: 'T'
  }, {
    value: 1e9,
    symbol: 'G'
  }, {
    value: 1e6,
    symbol: 'M'
  }, {
    value: 1e3,
    symbol: 'k'
  }];

  for (var i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (num / si[i].value).toFixed(digits).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].symbol;
    }
  }

  return num.toString();
}
/**
 * 10000 => "10,000"
 * @param {number} num
 */

export function toThousandFilter(num) {
  return (+num || 0).toString().replace(/^-?\d+/g, function (m) {
    return m.replace(/(?=(?!\b)(\d{3})+$)/g, ',');
  });
}
/**
 * Upper case first char
 * @param {String} string
 */

export function uppercaseFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
/**
 * Upper case first char
 * @param {String} string
 */
// const WECHAT_PAY = 0;    //微信支付
// const ALI_PAY = 1;        //支付宝支付
// const APPLE_PAY = 2;      //apple pay
// const SCORE_PAY = 3;      //积分支付
// const ETH_PAY = 4;      //数字资产支付
// const MP_PAY = 5;      //公众号支付
// const SMALL_PAY = 6;      //小程序支付
// const BALANCE_PAY = 10;      //余额支付

export function toPayChannelName(pay_channel) {
  var pay_channel_name = '';

  if (pay_channel == 0) {
    pay_channel_name = '微信支付';
  } else if (pay_channel == 1) {
    pay_channel_name = '支付宝支付';
  } else if (pay_channel == 5) {
    pay_channel_name = '公众号支付';
  } else if (pay_channel == 6) {
    pay_channel_name = '小程序支付';
  } else if (pay_channel == 10) {
    pay_channel_name = '余额支付';
  }

  return pay_channel_name;
}
{
  /* <el-option :value="''" :label="'全部'" />
  <el-option :value="1" :label="'商品抵扣'" />
  <el-option :value="2" :label="'后台扣除'" />
  <el-option :value="21" :label="'后台增加'" />
  <el-option :value="22" :label="'取消订单'" />
  <el-option :value="31" :label="'注册'" />
  <el-option :value="32" :label="'签到'" />
  <el-option :value="33" :label="'完成评价'" />
  <el-option :value="34" :label="'分享商品'" />
  <el-option :value="35" :label="'邀请好友'" />
  <el-option :value="36" :label="'成为VIP'" />
  <el-option :value="37" :label="'素材被采纳'" /> */
}
export function getScoreTypeName(type) {
  var type_name = '';

  if (type == 1) {
    type_name = '商品抵扣';
  } else if (type == 2) {
    type_name = '后台扣除';
  } else if (type == 21) {
    type_name = '后台增加';
  } else if (type == 22) {
    type_name = '取消订单';
  } else if (type == 31) {
    type_name = '注册';
  } else if (type == 32) {
    type_name = '签到';
  } else if (type == 33) {
    type_name = '完成评价';
  } else if (type == 34) {
    type_name = '分享商品';
  } else if (type == 35) {
    type_name = '邀请好友';
  } else if (type == 36) {
    type_name = '成为VIP';
  } else if (type == 37) {
    type_name = '素材被采纳';
  }

  return type_name;
}
/**
 * 货币过滤器
 * @param {Number} amount 金额
 */

export function currency(amount) {
  amount = Number(amount);
  if (isNaN(amount)) return 'NaN';
  return amount.toFixed(2);
}