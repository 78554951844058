import Cookies from 'js-cookie';
var TokenKey = 'Admin-Token';
var IsShop = 'Is-Shop';
var ShopId = 'Shop-Id';
export function getCookie(d) {
  return Cookies.get(d);
}
export function setCookie(name, data) {
  return Cookies.set(name, data);
}
export function getToken() {
  return Cookies.get(TokenKey);
}
export function setToken(token) {
  window.localStorage.setItem('token', token);
  return Cookies.set(TokenKey, token);
}
export function removeToken() {
  window.localStorage.setItem('token', '');
  return Cookies.remove(TokenKey);
}
export function getIsShop() {
  if (Cookies.get(IsShop) == 'false') {
    return false;
  } else if (Cookies.get(IsShop) == 'true') {
    return true;
  } else {
    return Cookies.get(IsShop);
  }
}
export function setIsShop(is_shop) {
  window.localStorage.setItem('is_shop', is_shop);
  return Cookies.set(IsShop, is_shop);
}
export function removeIsShop() {
  window.localStorage.setItem('is_shop', '');
  return Cookies.remove(IsShop);
}
export function getShopId() {
  return Cookies.get(ShopId);
}
export function setShopId(shop_id) {
  window.localStorage.setItem('shop_id', shop_id);
  return Cookies.set(ShopId, shop_id);
}
export function removeShopId() {
  window.localStorage.setItem('shop_id', '');
  return Cookies.remove(ShopId);
}