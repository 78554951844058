//
//
//
//
//
//
import { postAction } from '@/api/manage';
import { getCookie, setCookie } from '@/utils/auth';
export default {
  name: 'App',
  data: function data() {
    return {};
  },
  created: function created() {
    // postAction('/base/config-api/switch-config',{
    // }).then((d)=>{
    //   setCookie('o2oParams',d.data.o2oParams)
    //   setCookie('liveParams',d.data.liveParams)
    //   setCookie('crmParams',d.data.crmParams)
    // }).catch((d)=>{
    // })
    window.addEventListener('beforeprint', function (_e) {
      console.log('开始打印');
      document.querySelector('#app section.app-main').style.width = '760px';
    });
    window.addEventListener('afterprint', function (_e) {
      console.log('结束打印');
      document.querySelector('#app section.app-main').style.width = '';
    });
  }
};