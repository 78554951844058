import _toConsumableArray from "/builds/ytw/admin/node_modules/@babel/runtime-corejs2/helpers/esm/toConsumableArray";
import "core-js/modules/es6.function.name";
import _createForOfIteratorHelper from "/builds/ytw/admin/node_modules/@babel/runtime-corejs2/helpers/esm/createForOfIteratorHelper";
import _objectSpread from "/builds/ytw/admin/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import { constantRoutes } from '@/router';
import Layout from '@/layout';
import EmptyTemplate from '@/layout/EmptyTemplate';
/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */

function hasPermission(roles, route) {
  if (route.meta && route.meta.roles) {
    return roles.some(function (role) {
      return route.meta.roles.includes(role);
    });
  } else {
    return true;
  }
}
/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */


export function filterAsyncRoutes(routes, roles) {
  var res = [];
  routes.forEach(function (route) {
    var tmp = _objectSpread({}, route);

    if (hasPermission(roles, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, roles);
      }

      res.push(tmp);
    }
  });
  return res;
}

function generateChildRouters(data) {
  var routers = [];

  var _iterator = _createForOfIteratorHelper(data),
      _step;

  try {
    var _loop = function _loop() {
      item = _step.value;
      var component = item.component;
      var menu = {
        path: item.path,
        name: item.name,
        redirect: item.redirect,
        component: null,
        hidden: item.hidden,
        meta: {
          title: item.title,
          icon: item.icon,
          noCache: item.noCache,
          affix: item.affix,
          activeMenu: item.activeMenu
        }
      }; // if (item.pid === null) {//Layout组件特殊处理
      //   if (item.component.indexOf("layout") >= 0) {
      //     menu.component = Layout
      //   }
      // }else if(item.children && item.children.length > 0){
      //   menu.component = EmptyTemplate
      // } else {
      //   menu.component = resolve => require(['@/views/' + component + '.vue'], resolve)
      // }

      if (item.component.indexOf("layout") >= 0) {
        menu.component = Layout;
      } else {
        menu.component = function (resolve) {
          return require(['@/views/' + component + '.vue'], resolve);
        };
      }

      if (item.alwaysShow) {
        menu.alwaysShow = true;
      }

      if (item.children && item.children.length > 0) {
        menu.children = _toConsumableArray(generateChildRouters(item.children));
      }

      routers.push(menu);
    };

    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var item;

      _loop();
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }

  return routers;
}

var state = {
  routes: [],
  addRoutes: []
};
var ROUTES_MAPS = null;

function routes2map(routes) {
  var r = {};
  routes.forEach(function (route) {
    r[route.name] = true;
    if (route.children) Object.assign(r, routes2map(route.children));
  });
  return r;
}

var mutations = {
  SET_ROUTES: function SET_ROUTES(state, routes) {
    state.addRoutes = routes;
    state.routes = constantRoutes.concat(routes);
  }
};
var actions = {
  generateRoutes: function generateRoutes(_ref, roleRoutes) {
    var commit = _ref.commit;
    return new Promise(function (resolve) {
      // console.log(roleRoutes)
      var accessedRoutes = generateChildRouters(roleRoutes); // console.log(accessedRoutes)

      var menuEnd1 = {
        path: '/',
        redirect: '/dashboard',
        hidden: true
      };
      accessedRoutes.push(menuEnd1);
      var menuEnd2 = {
        path: '*',
        redirect: '/welcome/main',
        hidden: true
      };
      accessedRoutes.push(menuEnd2);
      commit('SET_ROUTES', accessedRoutes);
      resolve(accessedRoutes);
    });
  },
  clearRoutes: function clearRoutes(_ref2, roleRoutes) {
    var commit = _ref2.commit;
    return new Promise(function (resolve) {
      commit('SET_ROUTES', []);
      resolve('');
    });
  },
  checkPermission: function checkPermission(_store, roleName) {
    if (!ROUTES_MAPS) ROUTES_MAPS = routes2map(state.addRoutes);
    return ROUTES_MAPS[roleName];
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};