var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.item.hidden
    ? _c(
        "div",
        { staticClass: "menu-wrapper" },
        [
          _vm.hasOneShowingChild(_vm.item.children, _vm.item) &&
          (!_vm.onlyOneChild.children || _vm.onlyOneChild.noShowingChildren) &&
          !_vm.item.alwaysShow
            ? [
                _vm.onlyOneChild.meta
                  ? _c(
                      "app-link",
                      { attrs: { to: _vm.resolvePath(_vm.onlyOneChild.path) } },
                      [
                        _c(
                          "el-menu-item",
                          {
                            class: { "submenu-title-noDropdown": !_vm.isNest },
                            attrs: {
                              index: _vm.resolvePath(_vm.onlyOneChild.path)
                            },
                            on: {
                              click: function($event) {
                                return _vm.selectItem(
                                  _vm.item.children,
                                  _vm.basePath
                                )
                              }
                            }
                          },
                          [
                            _c("item", {
                              attrs: {
                                icon: _vm.item.meta && _vm.item.meta.icon,
                                title: _vm.item.meta.title
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ]
            : [
                _vm.item.meta
                  ? _c(
                      "app-link",
                      { attrs: { to: _vm.resolvePath(_vm.item.path) } },
                      [
                        _c(
                          "el-menu-item",
                          {
                            class: { "submenu-title-noDropdown": !_vm.isNest },
                            attrs: { index: _vm.resolvePath(_vm.item.path) },
                            on: {
                              click: function($event) {
                                return _vm.selectItem(
                                  _vm.item.children,
                                  _vm.basePath
                                )
                              }
                            }
                          },
                          [
                            _c("item", {
                              attrs: {
                                icon: _vm.item.meta && _vm.item.meta.icon,
                                title: _vm.item.meta.title
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ]
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }