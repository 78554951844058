import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);
/* Layout */

import Layout from '@/layout';
/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */

export var constantRoutes = [{
  path: '/redirect',
  component: Layout,
  hidden: true,
  children: [{
    path: '/redirect/:path*',
    component: function component() {
      return import('@/views/base/redirect/index');
    }
  }]
}, {
  path: '/login',
  component: function component() {
    return import('@/views/base/login/index');
  },
  hidden: true
}, {
  path: '/auth-redirect',
  component: function component() {
    return import('@/views/base/login/auth-redirect');
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return import('@/views/base/error-page/404');
  },
  hidden: true
}, {
  path: '/401',
  component: function component() {
    return import('@/views/base/error-page/401');
  },
  hidden: true
}, {
  path: '/welcome',
  component: Layout,
  redirect: '/welcome/main',
  meta: {
    title: '欢迎您',
    icon: 'dashboard'
  },
  children: [{
    path: 'main',
    component: function component() {
      return import('@/views/base/welcome/index');
    },
    name: 'Main',
    meta: {
      title: '欢迎您'
    }
  }]
} // {
//   path: '/floor',
//   component: Layout,
//   redirect: '/floor/home',
//   name: 'Floor',
//   meta: {
//     title: '商城装修',
//     icon: 'table'
//   },
//   children: [{
//     path: 'home',
//     component: () => import('@/views/floor/floorEdit'),
//     name: 'Home',
//     meta: {
//       title: '首页装修'
//     }
//   }, {
//     path: 'floorList',
//     component: () => import('@/views/floor/floorList'),
//     name: 'FloorList',
//     meta: {
//       title: '装修列表'
//     }
//   }, {
//     path: 'floorEdit',
//     component: () => import('@/views/floor/floorEdit'),
//     name: 'Home',
//     hidden: true,
//     meta: {
//       title: '装修详情',
//       activeMenu: '/floor/floorList'
//     }
//   }, {
//     path: 'popup',
//     component: () => import('@/views/floor/indexPopup'),
//     name: 'Popup',
//     meta: {
//       title: '首页弹窗'
//     }
//   }, {
//     path: 'richList',
//     component: () => import('@/views/floor/contentList'),
//     name: 'RichList',
//     meta: {
//       title: '富文本列表'
//     }
//   }, {
//     path: 'richEdit',
//     component: () => import('@/views/floor/contentEdit'),
//     name: 'RichEdit',
//     hidden: true,
//     meta: {
//       title: '富文本详情',
//       activeMenu: '/floor/richList'
//     }
//   }]
// },
// {
//   path: '/good',
//   component: Layout,
//   redirect: '/good/goodManage/goodList',
//   name: 'Good',
//   meta: {
//     title: '商品',
//     icon: 'nested'
//   },
//   children: [{
//     path: 'goodManage',
//     component: () => import('@/views/good/goodManage/index'),
//     name: 'GoodManage',
//     meta: {
//       title: '商品管理'
//     },
//     redirect: '/good/goodManage/goodList',
//     children: [{
//       path: 'goodList',
//       component: () => import('@/views/good/goodManage/goods/list'),
//       name: 'GoodList',
//       meta: {
//         title: '商品列表'
//       }
//     },
//     {
//       path: 'goodEdit',
//       component: () => import('@/views/good/goodManage/goods/edit'),
//       name: 'GoodEdit',
//       hidden: true,
//       meta: {
//         title: '商品编辑',
//         activeMenu: '/good/goodManage/goodList'
//       }
//     },
//     {
//       path: 'goodCreate',
//       component: () => import('@/views/good/goodManage/goods/edit'),
//       name: 'GoodCreate',
//       hidden: true,
//       meta: {
//         title: '商品新增',
//         activeMenu: '/good/goodManage/goodList'
//       }
//     },
//     {
//       path: 'categoryList',
//       component: () => import('@/views/good/goodManage/category/list'),
//       name: 'CategoryList',
//       meta: {
//         title: '分类管理'
//       }
//     }
//     ]
//   }
//   , {
//     path: 'setting',
//     component: () => import('@/views/good/setting/index'), // Parent router-view
//     name: 'GoodSetting',
//     meta: {
//       title: '其他设置'
//     },
//     redirect: '/good/setting/description',
//     children: [{
//       path: 'postage',
//       component: () => import('@/views/good/setting/postage/list'),
//       name: 'Postage',
//       meta: {
//         title: '运费规则'
//       }
//     }, {
//       path: 'postageCreate',
//       component: () => import('@/views/good/setting/postage/edit'),
//       name: 'PostageCreate',
//       hidden: true,
//       meta: {
//         title: '新增运费规则',
//         activeMenu: '/good/setting/postage'
//       }
//     }, {
//       path: 'postageEdit',
//       component: () => import('@/views/good/setting/postage/edit'),
//       name: 'PostageEdit',
//       hidden: true,
//       meta: {
//         title: '修改运费规则',
//         activeMenu: '/good/setting/postage'
//       }
//     }, {
//       path: 'description',
//       component: () => import('@/views/good/setting/description/list'),
//       name: 'Description',
//       meta: {
//         title: '商品服务描述'
//       }
//     }
//     ,
//     {
//       path: 'persentList',
//       component: () => import('@/views/good/setting/material/list'),
//       name: 'PersentList',
//       meta: {
//         title: '商品素材管理'
//       }
//     },
//     {
//       path: 'userList',
//       component: () => import('@/views/good/setting/userMaterial/list'),
//       name: 'UserList',
//       meta: {
//         title: '用户素材列表'
//       }
//     }, {
//       path: 'persentSetting',
//       component: () => import('@/views/good/setting/persent/index'),
//       name: 'PersentSetting',
//       meta: {
//         title: '官方头像设置'
//       }
//     }
//     ]
//   }]
// },
//  {
//   path: '/order',
//   component: Layout,
//   redirect: '/order/search/orderList',
//   name: 'Order',
//   meta: {
//     title: '订单管理',
//     icon: 'table'
//   },
//   children: [{
//     path: 'search',
//     component: () => import('@/views/order/search/index'),
//     name: 'OrderSearch',
//     meta: {
//       title: '交易查询'
//     },
//     redirect: '/order/search/orderList',
//     children: [{
//       path: 'orderList',
//       component: () => import('@/views/order/search/order/list'),
//       name: 'OrderList',
//       meta: {
//         title: '订单查询'
//       }
//     }, {
//       path: 'orderInfo',
//       component: () => import('@/views/order/search/order/info'),
//       name: 'OrderInfo',
//       hidden: true,
//       meta: {
//         title: '订单详情',
//         activeMenu: '/order/search/orderList'
//       }
//     },
//     {
//       path: 'commentList',
//       component: () => import('@/views/order/search/comment/list'),
//       name: 'CommentList',
//       meta: {
//         title: '评价查询'
//       }
//     }
//     ]
//   }, {
//     path: 'orderHandle',
//     component: () => import('@/views/order/handle/index'), // Parent router-view
//     name: 'OrderHandle',
//     meta: {
//       title: '订单处理'
//     },
//     redirect: '/order/orderHandle/backList',
//     children: [
//     {
//       path: 'backList',
//       component: () => import('@/views/order/handle/back/list'),
//       name: 'BackList',
//       meta: {
//         title: '售后订单'
//       }
//     },
//     {
//       path: 'backInfo',
//       component: () => import('@/views/order/handle/back/info'),
//       name: 'BackInfo',
//       hidden: true,
//       meta: {
//         title: '售后订单详情',
//         activeMenu: '/order/orderHandle/backList'
//       }
//     },
//     {
//       path: 'orderSetting',
//       component: () => import('@/views/order/handle/setting/list'),
//       name: 'OrderSetting',
//       meta: {
//         title: '订单设置'
//       }
//     }
//     ]
//   }]
// },
// {
//   path: '/statistics',
//   component: Layout,
//   redirect: '/statistics/trade/dealStatistics',
//   name: 'Statistics',
//   meta: {
//     title: '统计管理',
//     icon: 'table'
//   },
//   children: [
//     {
//       path: 'trade',
//       component: () => import('@/views/statistics/trade/index'),
//       name: 'Trade',
//       meta: {
//         title: '交易数据'
//       },
//       redirect: '/statistics/trade/dealStatistics',
//       children: [
//         {
//           path: 'dealStatistics',
//           component: () => import('@/views/statistics/trade/deal/index'),
//           name: 'dealStatictics',
//           meta: {
//             title: '交易概况'
//           }
//         },
//         {
//           path: 'goodStatistics',
//           component: () => import('@/views/statistics/trade/good/index'),
//           name: 'goodStatistics',
//           meta: {
//             title: '商品统计'
//           }
//         },
//         {
//           path: 'orderStatistics',
//           component: () => import('@/views/statistics/trade/order/index'),
//           name: 'orderStatistics',
//           meta: {
//             title: '订单统计'
//           }
//         }
//       ]
//     },
//     {
//       path: 'user',
//       component: () => import('@/views/statistics/user/index'),
//       name: 'User',
//       meta: {
//         title: '用户数据'
//       },
//       redirect: '/statistics/user/userStatistics',
//       children: [
//         {
//           path: 'userStatistics',
//           component: () => import('@/views/statistics/user/user/index'),
//           name: 'userStatistics',
//           meta: {
//             title: '用户概况'
//           }
//         },
//         {
//           path: 'distributeStatistics',
//           component: () => import('@/views/statistics/user/distribute/index'),
//           name: 'distributeStatistics',
//           meta: {
//             title: '分销员概况'
//           }
//         }
//       ]
//     },
//     {
//       path: 'market',
//       component: () => import('@/views/statistics/market/index'),
//       meta: {
//         title: '营销数据'
//       },
//       redirect: '/statistics/market/scoreStatistics',
//       children: [
//         {
//           path: 'scoreStatistics',
//           component: () => import('@/views/statistics/market/score/index'),
//           name: 'scoreStatistics',
//           meta: {
//             title: '积分统计'
//           }
//         },
//         {
//           path: 'couponStatistics',
//           component: () => import('@/views/statistics/market/coupon/index'),
//           name: 'couponStatistics',
//           meta: {
//             title: '优惠券统计'
//           }
//         },
//         {
//           path: 'groupStatistics',
//           component: () => import('@/views/statistics/market/group/index'),
//           name: 'groupStatistics',
//           meta: {
//             title: '拼团统计'
//           }
//         },
//         {
//           path: 'seckillStatistics',
//           component: () => import('@/views/statistics/market/seckill/index'),
//           name: 'seckillStatistics',
//           meta: {
//             title: '秒杀统计'
//           }
//         },
//         {
//           path: 'bargainStatistics',
//           component: () => import('@/views/statistics/market/bargain/index'),
//           name: 'bargainStatistics',
//           meta: {
//             title: '砍价统计'
//           }
//         },
//         {
//           path: 'bookStatistics',
//           component: () => import('@/views/statistics/market/book/index'),
//           name: 'bookStatistics',
//           meta: {
//             title: '预售统计'
//           }
//         }
//       ]
//     }
//   ]
// },
// {
//   path: '/marketing',
//   component: Layout,
//   redirect: '/marketing/coupon/couponList',
//   name: 'Marketing',
//   meta: {
//     title: '营销',
//     icon: 'table'
//   },
//   children: [{
//     path: 'coupon',
//     component: () => import('@/views/marketing/coupon/index'),
//     name: 'Coupon',
//     meta: {
//       title: '优惠券'
//     },
//     redirect: '/marketing/coupon/couponList',
//     children: [{
//       path: 'couponList',
//       component: () => import('@/views/marketing/coupon/setting/list'),
//       name: 'CouponList',
//       meta: {
//         title: '优惠券管理'
//       }
//     }, {
//       path: 'couponEdit',
//       component: () => import('@/views/marketing/coupon/setting/edit'),
//       name: 'CouponEdit',
//       hidden: true,
//       meta: {
//         title: '添加优惠券',
//         activeMenu: '/marketing/coupon/couponList'
//       }
//     }, {
//       path: 'couponDetailed',
//       component: () => import('@/views/marketing/coupon/setting/detailed'),
//       name: 'CouponDetailed',
//       hidden: true,
//       meta: {
//         title: '明细',
//         activeMenu: '/marketing/coupon/couponList'
//       }
//     }, {
//       path: 'couponGrant',
//       component: () => import('@/views/marketing/coupon/grant/list'),
//       name: 'CouponGrant',
//       meta: {
//         title: '发放设置'
//       }
//     }]
//   }, {
//     path: 'seckill',
//     component: () => import('@/views/marketing/seckill/index'), // Parent router-view
//     name: 'Seckill',
//     alwaysShow: true,
//     meta: {
//       title: '秒杀'
//     },
//     redirect: '/marketing/seckill/seckillList',
//     children: [{
//       path: 'seckillList',
//       component: () => import('@/views/marketing/seckill/setting/list'),
//       name: 'SeckillList',
//       meta: {
//         title: '秒杀设置'
//       }
//     }, {
//       path: 'setupA',
//       component: () => import('@/views/marketing/seckill/setting/setupA'),
//       name: 'SetupA',
//       hidden: true,
//       meta: {
//         title: '商品设置',
//         activeMenu: '/marketing/seckill/seckillList'
//       }
//     }, {
//       path: 'setupB',
//       component: () => import('@/views/marketing/seckill/setting/setupB'),
//       name: 'SetupB',
//       hidden: true,
//       meta: {
//         title: '商品设置',
//         activeMenu: '/marketing/seckill/seckillList'
//       }
//     }]
//   }, {
//     path: 'group',
//     component: () => import('@/views/marketing/group/index'), // Parent router-view
//     name: 'Group',
//     meta: {
//       title: '拼团'
//     },
//     redirect: '/marketing/group/groupManage',
//     children: [
//       {
//         path: 'groupManage',
//         component: () => import('@/views/marketing/group/manage/list'),
//         name: 'GroupManage',
//         meta: {
//           title: '拼团管理'
//         }
//       }, {
//         path: 'categoryList',
//         component: () => import('@/views/marketing/group/category/list'),
//         name: 'CategoryList',
//         meta: {
//           title: '拼团分类'
//         }
//       }, {
//         path: 'groupList',
//         component: () => import('@/views/marketing/group/setting/list'),
//         name: 'GroupList',
//         hidden: true,
//         meta: {
//           title: '拼团设置'
//         }
//       }
//     ]
//   }, {
//     path: 'score',
//     component: () => import('@/views/marketing/score/index'),
//     name: 'Score',
//     alwaysShow: true,
//     meta: {
//       title: '积分管理'
//     },
//     redirect: '/marketing/score/receivedList',
//     children: [{
//       path: 'receivedList',
//       component: () => import('@/views/marketing/score/received/list'),
//       name: 'ReceivedList',
//       meta: {
//         title: '积分日志'
//       }
//     }, {
//       path: 'usedList',
//       component: () => import('@/views/marketing/score/used/list'),
//       name: 'UsedList',
//       hidden: true,
//       meta: {
//         title: '积分明细',
//         activeMenu: '/marketing/score/receivedList'
//       }
//     }]
//   }, {
//     path: 'bargain',
//     component: () => import('@/views/marketing/bargain/index'), // Parent router-view
//     name: 'Bargain',
//     alwaysShow: true,
//     meta: {
//       title: '砍价'
//     },
//     redirect: '/marketing/bargain/bargainList',
//     children: [{
//       path: 'bargainList',
//       component: () => import('@/views/marketing/bargain/setting/list'),
//       name: 'BargainList',
//       meta: {
//         title: '砍价设置'
//       }
//     }]
//   }]
// },
// {
//   path: '/finance',
//   component: Layout,
//   redirect: '/finance/cash/userCash',
//   name: 'finance',
//   meta: {
//     title: '财务',
//     icon: 'nested'
//   },
//   children: [
//     {
//       path: 'cash',
//       component: () => import('@/views/finance/cash/index'),
//       name: 'cash',
//       meta: {
//         title: '提现管理'
//       },
//       redirect: '/finance/cash/userCash',
//       children: [
//         {
//           path: 'userCash',
//           component: () => import('@/views/finance/cash/userCash/index'),
//           name: 'userCash',
//           meta: {
//             title: '分销员提现'
//           }
//         },
//         {
//           path: 'businessCash',
//           component: () => import('@/views/finance/cash/businessCash/index'),
//           name: 'businessCash',
//           meta: {
//             title: '商家提现'
//           }
//         }
//       ]
//     }, {
//       path: 'financeDetails',
//       component: () => import('@/views/finance/financeDetails/index'),
//       name: 'financeDetails',
//       meta: {
//         title: '财务明细'
//       },
//       redirect: '/finance/financeDetails/commissionList',
//       children: [
//         {
//           path: 'commissionList',
//           component: () => import('@/views/finance/financeDetails/commission/list'),
//           name: 'commissionList',
//           meta: {
//             title: '分销员佣金'
//           }
//         },{
//           path: 'commissionDetails',
//           component: () => import('@/views/finance/financeDetails/commission/details'),
//           name: 'commissionDetails',
//           hidden: true,
//           meta: {
//           }
//         },
//         {
//           path: 'supplierSubLedgerList',
//           component: () => import('@/views/finance/financeDetails/supplierSubLedger/list'),
//           name: 'supplierSubLedgerList',
//           meta: {
//             title: '供应商分账'
//           }
//         },{
//           path: 'supplierSubLedgerDetails',
//           component: () => import('@/views/finance/financeDetails/supplierSubLedger/details'),
//           name: 'supplierSubLedgerDetails',
//           hidden: true,
//           meta: {
//           }
//         }
//       ]
//     },{
//       path: 'serviceCharge',
//       component: () => import('@/views/finance/serviceCharge/index'),
//       name: 'serviceCharge',
//       meta: {
//         title: '提现手续费设置'
//       },
//     },{
//       path: 'reconciliation',
//       component: () => import('@/views/finance/reconciliation'),
//       name: 'reconciliation',
//       meta: {
//         title: '资金对账'
//       }
//     },{
//       path: 'reconciliationDetails',
//       component: () => import('@/views/finance/reconciliation/details'),
//       name: 'reconciliationDetails',
//       hidden: true,
//       meta: {
//         title: '资金对账'
//       }
//     }]
// },
// {
//   path: '/content',
//   component: Layout,
//   redirect: '/content/feedback',
//   name: 'content',
//   meta: {
//     title: '内容管理',
//     icon: 'nested'
//   },
//   children: [
//     {
//       path: 'agreementManage',
//       component: () => import('@/views/content/agreementManage/index'),
//       name: 'agreementManage',
//       meta: {
//         title: '协议管理'
//       }
//     },
//     {
//       path: 'editAgreement',
//       component: () => import('@/views/content/agreementManage/editAgreement/edit'),
//       name: 'editAgreement',
//       hidden: true,
//       meta: {
//         title: '协议编辑'
//       }
//     },
//     {
//       path: 'checkAgreement',
//       component: () => import('@/views/content/agreementManage/checkAgreement/check'),
//       name: 'checkAgreement',
//       hidden: true,
//       meta: {
//         title: '协议查看'
//       }
//     },
//     {
//       path: 'bannerManage',
//       component: () => import('@/views/content/bannerManage/index'),
//       name: 'bannerManage',
//       meta: {
//         title: 'banner管理'
//       }
//     },
//     {
//       path: 'editBanner',
//       component: () => import('@/views/content/bannerManage/editBanner/edit'),
//       name: 'editBanner',
//       hidden: true,
//       meta: {
//         title: 'banner编辑'
//       }
//     },
//     {
//       path: 'feedback',
//       component: () => import('@/views/content/feedback/index'),
//       name: 'feedback',
//       meta: {
//         title: '意见反馈'
//       }
//     },
//     {
//       path: 'checkFeedback',
//       component: () => import('@/views/content/feedback/checkFeedback/check'),
//       name: 'checkFeedback',
//       hidden: true,
//       meta: {
//         title: '反馈查看'
//       }
//     },
//     {
//       path: 'handleFeedback',
//       component: () => import('@/views/content/feedback/handleFeedback/edit'),
//       name: 'handleFeedback',
//       hidden: true,
//       meta: {
//         title: '反馈处理'
//       }
//     },
//     {
//       path: 'messageManage',
//       component: () => import('@/views/content/messageManage/index'),
//       name: 'messageManage',
//       meta: {
//         title: '公告管理'
//       }
//     },
//     {
//       path: 'editMessage',
//       component: () => import('@/views/content/messageManage/editMessage/edit'),
//       name: 'editMessage',
//       hidden: true,
//       meta: {
//         title: '公告编辑'
//       }
//     },
//     {
//       path: 'checkMessage',
//       component: () => import('@/views/content/messageManage/checkMessage/check'),
//       name: 'checkMessage',
//       hidden: true,
//       meta: {
//         title: '公告查看'
//       }
//     },{
//       path: 'poster',
//       component: () => import('@/views/content/poster/index'),
//       name: 'Poster',
//       meta: {
//         title: '海报背景设置'
//       }
//     }]
// }
// ,{
//   path: '/distribution',
//   component: Layout,
//   redirect: '/distribution/base/set',
//   name: 'Distribution',
//   meta: {
//     title: '分销',
//     icon: 'table'
//   },
//   children: [
//     {
//       path: 'administration',
//       name: 'Administration',
//       component: () => import('@/views/distribution/administration/index'),
//       meta: {
//         title: '分销管理员'
//       }
//     },
//     {
//       path: 'administration/detail',
//       name: 'AdministrationDetail',
//       component: () => import('@/views/distribution/administration/detail'),
//       hidden: true,
//       meta: {
//         title: '分销管理详情',
//         activeMenu: '/distribution/administration'
//       }
//     },
//     {
//       path: 'dividend/set',
//       name: 'DividendSet',
//       component: () => import('@/views/distribution/settings'),
//       meta: {
//         title: '分佣设置'
//       }
//     },
//     {
//       path: 'dividend/red',
//       name: 'DividendRed',
//       component: () => import('@/views/distribution/dividend'),
//       meta: {
//         title: '分红设置'
//       }
//     },
//     {
//       path: 'base/set',
//       component: () => import('@/views/distribution/base/set'),
//       name: 'BaseSet',
//       meta: {
//         title: '基础设置'
//       }
//     }
//   ]
// }
// {
//   path: '/',
//   component: Layout,
//   redirect: '/dashboard',
//   meta: {
//     title: '数据统计',
//     icon: 'dashboard'
//   },
//   children: [{
//     path: 'dashboard',
//     component: () => import('@/views/base/dashboard/index'),
//     name: 'Dashboard',
//     meta: {
//       title: '数据统计',
//       icon: 'dashboard',
//       affix: true
//     }
//   }]
// },
// {
//   path: '/admin',
//   component: Layout,
//   redirect: '/admin/userList',
//   alwaysShow: true, // will always show the root menu
//   name: 'Admin',
//   meta: {
//     title: '管理员',
//     icon: 'lock'
//   },
//   children: [{
//       path: 'userList',
//       component: () => import('@/views/system/user/list'),
//       name: 'UserList',
//       meta: {
//         title: '操作员管理'
//       }
//     },
//     {
//       path: 'role',
//       component: () => import('@/views/system/roles/list'),
//       name: 'PagePermission',
//       meta: {
//         title: '角色管理'
//       }
//     },
//     {
//       path: 'menuList',
//       component: () => import('@/views/system/menus/list'),
//       name: 'MenuList',
//       meta: {
//         title: '菜单管理'
//       }
//     }
//   ]
// },
// {
//   path: '/nested',
//   component: Layout,
//   redirect: '/nested/menu1/menu1-1',
//   name: 'Nested',
//   meta: {
//     title: '用户中心',
//     icon: 'nested'
//   },
//   children: [{
//     path: 'menu1',
//     component: () => import('@/views/base/nested/menu1/index'), // Parent router-view
//     name: 'Menu1',
//     meta: {
//       title: '用户管理'
//     },
//     redirect: '/nested/menu1/menu1-1',
//     children: [{
//         path: 'menu1-1',
//         component: () => import('@/views/base/nested/menu1/menu1-1'),
//         name: 'Menu1-1',
//         meta: {
//           title: '用户日志'
//         }
//       },
//       {
//         path: 'menu1-2',
//         component: () => import('@/views/base/nested/menu1/menu1-2'),
//         name: 'Menu1-2',
//         meta: {
//           title: '临时分销员设置'
//         }
//       }
//     ]
//   }, {
//     path: 'menu2',
//     component: () => import('@/views/base/nested/menu1/index'), // Parent router-view
//     name: 'Menu2',
//     meta: {
//       title: '会员'
//     },
//     redirect: '/nested/menu2/menu2-1',
//     children: [{
//         path: 'menu2-1',
//         component: () => import('@/views/base/nested/menu1/menu1-1'),
//         name: 'Menu2-1',
//         meta: {
//           title: '用户日志'
//         }
//       },
//       {
//         path: 'menu2-2',
//         component: () => import('@/views/base/nested/menu1/menu1-2'),
//         name: 'Menu2-2',
//         meta: {
//           title: '临时分销员设置'
//         }
//       }
//     ]
//   }]
// }
];
/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
// export const asyncRoutes = [
// ]

var createRouter = function createRouter() {
  return new Router({
    mode: 'history',
    // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};

var router = createRouter(); // Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465

export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
export default router;