exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n", ""]);

// exports
exports.locals = {
	"menuText": "#bfcbd9",
	"menuOneText": "#323233",
	"menuActiveText": "#00a4ff",
	"subMenuActiveText": "#f4f4f5",
	"subMenuOneActiveText": "#323233",
	"menuBg": "#444",
	"whiteBg": "#fff",
	"menuHover": "#263445",
	"menuOneHover": "#fff",
	"subMenuBg": "#1f2d3d",
	"subMenuHover": "#001528",
	"subMenuOneHover": "#ebedf0",
	"sideBarWidth": "275px"
};