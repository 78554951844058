var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { "has-logo": _vm.showLogo } },
    [
      _vm.showLogo && _vm.isCollapse
        ? _c("logo", { attrs: { collapse: _vm.isCollapse } })
        : _vm._e(),
      _vm._v(" "),
      _vm.isCollapse
        ? _c(
            "el-scrollbar",
            { attrs: { "wrap-class": "scrollbar-wrapper" } },
            [
              _c(
                "el-menu",
                {
                  attrs: {
                    "default-active": _vm.activeMenu,
                    collapse: _vm.isCollapse,
                    "background-color": _vm.variables.menuBg,
                    "text-color": _vm.variables.menuText,
                    "unique-opened": false,
                    "active-text-color": _vm.variables.menuActiveText,
                    "collapse-transition": false,
                    mode: "vertical"
                  }
                },
                _vm._l(_vm.permission_routes, function(route, index) {
                  return _c("sidebar-item", {
                    key: index,
                    attrs: { item: route, "base-path": route.path }
                  })
                }),
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isCollapse
        ? _c("div", { staticClass: "nm-sidebar" }, [
            _c(
              "div",
              { staticClass: "nm-sidebar-menu" },
              [
                _vm.showLogo
                  ? _c("logo", { attrs: { collapse: _vm.isCollapse } })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "el-menu",
                  {
                    attrs: {
                      "default-active": _vm.activeMenu,
                      collapse: _vm.isCollapse,
                      "background-color": _vm.variables.menuBg,
                      "text-color": _vm.variables.menuText,
                      "unique-opened": false,
                      "active-text-color": _vm.variables.menuActiveText,
                      "collapse-transition": false,
                      mode: "vertical"
                    }
                  },
                  _vm._l(_vm.permission_routes, function(route, index) {
                    return _c("sidebar-item-one", {
                      key: index,
                      attrs: {
                        menuLevel: _vm.menuLevel,
                        item: route,
                        "base-path": route.path
                      },
                      on: { clickItem: _vm.test }
                    })
                  }),
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "nm-sidebar-fool" }, [
                  _vm.isYt ? _c("p", [_vm._v("古邑")]) : _vm._e(),
                  _vm._v(
                    "\n        v" +
                      _vm._s(_vm.version) +
                      " (" +
                      _vm._s(_vm.build) +
                      ")"
                  ),
                  _c("br"),
                  _vm._v("\n        © 长思科技\n      ")
                ])
              ],
              1
            ),
            _vm._v(" "),
            _vm.menuLevel > 2
              ? _c(
                  "div",
                  { staticClass: "nm-sidebar-menusub" },
                  [
                    _c("div", { staticClass: "sub-title" }, [
                      _vm._v(_vm._s(_vm.title))
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-menu",
                      {
                        attrs: {
                          "default-active": _vm.activeMenu,
                          "background-color": _vm.variables.whiteBg,
                          "text-color": _vm.variables.menuOneText,
                          "unique-opened": false,
                          "active-text-color": _vm.variables.menuActiveText,
                          "collapse-transition": false,
                          mode: "vertical"
                        }
                      },
                      _vm._l(_vm.sub_routes, function(route, index) {
                        return _c("sidebar-item-two", {
                          key: index,
                          attrs: {
                            menuLevel: _vm.menuLevel,
                            item: route,
                            "base-path": _vm.resolvePath(route.path)
                          }
                        })
                      }),
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }